function iosDownload() {
    window.location.href = "itms-services:///?action=download-manifest&url=https://xz.biwenwl.cn/mile.plist";
}
function androidDownload() {
    window.location.href = "http://dd.biwenwl.cn/android.apk"
}

export {
    iosDownload,
    androidDownload
}
