// //判断是否在微信中打开
// var ua = navigator.userAgent.toLowerCase();
// var isWeixin = ua.indexOf('micromessenger') != -1;
// if( isWeixin ){
//   //执行逻辑
// }else{
//   //执行逻辑
// }

export function ifIos () {
    var u = navigator.userAgent;
    var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); 
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
    // console.log(isIos,'ifIos')
    if(isIos){
    //执行逻辑
        return true
    }else if(isAndroid){
    //执行逻辑
        return false
    }
};

//判断是否在ios或者安卓打开
